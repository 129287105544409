import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Percent, Decimal } from './number';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "number"
    }}>{`Number`}</h1>
    <p>{`Numbers do not change between locales, but how they are formatted does change. Not only that, but
numbers change the translation `}<em parentName="p">{`context`}</em>{`. A word might be translated differently if it comes after a number.
Therefore, stele understands how to turn these numbers in to ICU messages for our compiler.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import * as React from 'react'
import { Percent, Decimal } from '@patreon/stele'

function MyComponent(props) {
    return (
        <Text intl>
            <p>
                We have <Decimal value={dauAverage} /> DAU
            </p>
            <p>
                They are <Percent value={appleFractionDecimal} /> likely to buy
                a widget
            </p>
        </Text>
    )
}
`}</code></pre>
    <h2 {...{
      "id": "example-message"
    }}>{`Example message`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
    dauAverage, number, decimal
}
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Percent} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'() => {\n  const [count, setCount] = React.useState(1)\n  return (\n    <>\n      <div>\n        <input\n          value={count}\n          onChange={e => setCount(e.target.value)}\n          type=\"number\"\n        />\n      </div>\n      <Percent value={count} />\n      <br />\n      <Decimal value={count} />\n      <br />\n      $\n      <Decimal\n        value={count}\n        minimumFractionDigits={2}\n        maximumFractionDigits={2}\n        minimumIntegerDigits={1}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Percent,
      Decimal
    }} mdxType="Playground">
    {() => {
        const [count, setCount] = React.useState(1);
        return <>
                <div>
                    <input value={count} onChange={e => setCount(e.target.value)} type="number" />
                </div>
                <Percent value={count} mdxType="Percent" />
                <br />
                <Decimal value={count} mdxType="Decimal" />
                <br />
                $<Decimal value={count} minimumFractionDigits={2} maximumFractionDigits={2} minimumIntegerDigits={1} mdxType="Decimal" />
            </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      